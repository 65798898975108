import React, { useState } from "react";
import { CountryDropdown } from 'react-country-region-selector';
import db from "./firestore";
import { collection, addDoc } from "firebase/firestore/lite";
import { loadStripe } from '@stripe/stripe-js';
import ReactMarkdown from 'react-markdown';
import tags from "../components/tags";
import cms, { stripeConfig } from "./cms/cms";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeConfig.key);

const sortedTags = Object.entries(tags).sort()

const JobPostForm = () => {
  const [title, setTitle] = useState(null);
  const [link, setLink] = useState(null);
  const [company, setCompany] = useState(null);
  const [location, setLocation] = useState(null);
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const [remote, setRemote] = cms.keyword === "Remote" ? useState("remote") : useState("on_site");
  const [country, setCountry] = useState("");
  const [salaryFrom, setSalaryFrom] = useState(null);
  const [salaryTo, setSalaryTo] = useState(null);
  const [currency, setCurrency] = useState("$");
  const [dataUri, setDataUri] = useState("");
  const [imageUploadError, setImageUploadError] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [error, setError] = useState(null);
  const [libraryTags, setLibraryTags] = useState({});

  const handleSubmit = async e => {
    setButtonClicked(true);
    e.preventDefault();
    const doc = await
      addDoc(
        collection(db, "pending_jobs"),
        {
          title: title,
          location: location,
          country: country,
          company: company,
          description: description,
          remote: remote,
          salaryFrom: salaryFrom,
          salaryTo: salaryTo,
          currency: currency,
          image: dataUri,
          link: link,
          date: Date.now(),
          tags: Object.values(libraryTags).filter(tag => tag.length > 0),
          websites: [cms.keyword],
        }
      );

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: stripeConfig.priceId,
        quantity: 1,
      }],
      mode: "payment",
      successUrl: `https://${cms.siteUrl}`,
      cancelUrl: `https://${cms.siteUrl}/post-job`,
      clientReferenceId: doc.id,
      customerEmail: email
    }).catch(error => setError(error));
    setError(error);
    setButtonClicked(false);
  }

  const saveImage = file => {
    if (!file) {
      setDataUri("");
    } else if (file.size < 1000000) {
      let reader = new FileReader();
      reader.onload = file => setDataUri(file.target.result);
      reader.readAsDataURL(file);
    } else {
      setImageUploadError("Error: File must be smaller than 1 MB");
    }
  }

  const TagSelector = ({ tagKeyProp }) =>
    <select
      placeholder="Choose"
      id={`tag-${tagKeyProp}`}
      className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
      value={libraryTags[tagKeyProp]}
      onChange={e => setLibraryTags({ ...libraryTags, ...{ [tagKeyProp]: e.target.value } })}>
      <option className="text-gray-500" value="">Select</option>
      {sortedTags.map(([k, v]) => <option key={`${tagKeyProp}-${k}`} value={k}>{v.name}</option>)}
    </select>

  return <form id="form" onSubmit={handleSubmit} className="max-w-7xl mx-auto my-10 px-4 sm:px-6">
    <div className="bg-white shadow-lg overflow-hidden sm:rounded-md px-4 pb-4 sm:px-6">
      <div className="mt-5">
        <div>
          <div>
            <h1 className="text-xl leading-6 font-medium text-gray-900">
              Create a standard job post
            </h1>
            <p className="mt-1 leading-5 text-gray-900">
              Reach thousands of {cms.keyword} Developers with a one-off job post for {cms.price}. Your job post will be featured on the homepage for 30 days.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="title" className="block font-medium leading-5 text-gray-700">
                Job Title
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input onChange={e => setTitle(e.target.value)} id="title" required className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm: sm:leading-5"
                  placeholder={`${cms.keyword} Developer`}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="description" className="block font-medium leading-5 text-gray-700">
                Job Description
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <textarea
                  required
                  onChange={e => setDescription(e.target.value)} id="description" rows="12" className="form-textarea block w-full transition duration-150 ease-in-out sm: sm:leading-5"
                  placeholder={`${cms.keyword} developer wanted`}
                ></textarea>
              </div>
              <p className="mt-2 text-gray-500">Write a few sentences about the job. Markdown is supported.</p>
            </div>

            {description ?
              <div className="sm:col-span-6">
                <h2 className="block font-medium leading-5 text-gray-700">Preview</h2>
                <ReactMarkdown className="min-w-full prose mt-2 p-4 border border-gray-200 rounded-md shadow-sm">{description}</ReactMarkdown>

              </div> : <></>
            }

            <div className="sm:col-span-4">
              <label htmlFor="link" className="block font-medium leading-5 text-gray-700">
                Job Application Link
              </label>
              <p className="leading-5 text-gray-500">Enter a valid URL or mailto address i.e. mailto:you@example.com</p>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input onChange={e => setLink(e.target.value)}
                  id="link" required
                  className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm: sm:leading-5"
                  placeholder="https://example.com/job or mailto:you@example.com"
                />
              </div>
            </div>



            <div className="sm:col-span-4">
              <div>
                <label htmlFor="salary_from" className="block font-medium leading-5 text-gray-700">Salary Range</label>
                <div className="mt-2 flex justify-between items-center">
                  <label htmlFor="salary_from" className="block font-medium leading-5 text-gray-700">From</label>
                  <span className="leading-5 text-gray-500" id="salary_from_optional">Optional</span>
                </div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <select value={currency} onChange={e => setCurrency(e.target.value)} aria-label="salary_from" className="form-select h-full py-0 pl-3 pr-8 border-transparent bg-transparent text-lg text-gray-500">
                      <option value="$">$</option>
                      <option value="£">£</option>
                      <option value="€">€</option>
                    </select>
                  </div>
                  <input onChange={e => setSalaryFrom(e.target.value)} id="salary_from" className="form-input block w-full pl-16" placeholder="100 000" />
                </div>
              </div>

              <div>
                <div className="mt-2 flex justify-between items-center">
                  <label htmlFor="salary_to" className="block font-medium leading-5 text-gray-700">To</label>
                  <span className="leading-5 text-gray-500" id="salary_to_optional">Optional</span>
                </div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <select value={currency} onChange={e => setCurrency(e.target.value)} aria-label="salary_to" className="form-select h-full py-0 pl-3 pr-8 border-transparent bg-transparent text-lg text-gray-500">
                      <option value="$">$</option>
                      <option value="£">£</option>
                      <option value="€">€</option>
                    </select>
                  </div>
                  <input onChange={e => setSalaryTo(e.target.value)} id="salary_to" className="form-input block w-full pl-16" placeholder="150 000" />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="company" className="block font-medium leading-5 text-gray-700">
                Company Name
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  onChange={e => setCompany(e.target.value)} id="company" required className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm:leading-5"
                  placeholder="Acme Inc."
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="location" className="block font-medium leading-5 text-gray-700">
                Location
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  onChange={e => setLocation(e.target.value)} id="location" required className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm:leading-5"
                  placeholder="City - State"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="country" className="block font-medium leading-5 text-gray-700">
                Country
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <CountryDropdown
                  className="form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  required
                  valueType="short"
                  style={{
                    fontSize: 16,
                  }}
                  value={country}
                  onChange={setCountry}
                  priorityOptions={["US", "GB", "CA", "DE", "NL", "FR", "ES", "IT"]}
                />
              </div>
            </div>

            {
              cms.keyword === "Scala" ?
                <div className="sm:col-span-4">
                  <label htmlFor="tag" className="block font-medium leading-5 text-gray-700">Libraries / Technologies</label>
                  <div className="flex justify-between">
                    <p className="leading-5 text-gray-500">Choose up to 5 technology tags ordered by importance</p>
                    <span className="leading-5 text-gray-500" id="tag_optional">Optional</span>
                  </div>
                  {
                    [...Array(5).keys()].map(key => <TagSelector key={key} tagKeyProp={key} />)
                  }
                </div> : <></>
            }

            {cms.keyword === "Remote" ? <></> :
              <fieldset className="sm:col-span-6">
                <legend className="text-base font-medium text-gray-900">
                  Remote
                </legend>
                <p className="leading-5 text-gray-500">Is your job remote friendly?</p>
                <div className="mt-4">
                  <div className="flex items-center">
                    <input
                      checked={remote === "remote"}
                      onChange={e => setRemote(e.target.value)}
                      id="remote"
                      value="remote"
                      name="remote"
                      type="radio"
                      className="form-radio h-4 w-4 text-red-600 transition duration-150 ease-in-out"
                    />
                    <label htmlFor="remote" className="ml-3">
                      <span className="block  leading-5 font-medium text-gray-700">Remote</span>
                    </label>
                  </div>
                  <div className="mt-4 flex items-center">
                    <input onChange={e => setRemote(e.target.value)}
                      checked={remote === "partially_remote"}
                      id="partially_remote"
                      value="partially_remote"
                      name="partially_remote"
                      type="radio"
                      className="form-radio h-4 w-4 text-red-600 transition duration-150 ease-in-out"
                    />
                    <label htmlFor="partially_remote" className="ml-3">
                      <span className="block  leading-5 font-medium text-gray-700">Partially Remote</span>
                    </label>
                  </div>
                  <div className="mt-4 flex items-center">
                    <input
                      onChange={e => setRemote(e.target.value)}
                      checked={remote === "on_site"}
                      id="on_site"
                      name="on_site"
                      value="on_site"
                      type="radio"
                      className="form-radio h-4 w-4 text-red-600 transition duration-150 ease-in-out"
                    />
                    <label htmlFor="on_site" className="ml-3">
                      <span className="block  leading-5 font-medium text-gray-700">On Site</span>
                    </label>
                  </div>
                </div>
              </fieldset>

            }

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  onChange={e => setEmail(e.target.value)} id="email" required type="email" className="flex-1 form-input block w-full min-w-0 rounded-md transition duration-150 ease-in-out sm: sm:leading-5"
                  placeholder="you@example.com"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <div className="flex justify-between">
                <label htmlFor="photo" className="block leading-5 font-medium text-gray-700">
                  Company logo
                </label>
                <span className="leading-5 text-gray-500" id="photo">Optional</span>
              </div>
              <div className="mt-2 sm:flex sm:items-center">
                <span className="h-10 w-10 overflow-hidden bg-gray-100">
                  {dataUri ? <img className="h-10 w-10" src={dataUri} /> : <></>}
                </span>
                <span className="w-full sm:ml-5 mt-2 sm:mt-0 block rounded-md shadow-sm py-2 px-3 border border-gray-300 rounded-md leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                  <input
                    onChange={event => saveImage(event.target.files[0] || null)}
                    type="file"
                    id="file-selector"
                    accept=".jpg, .jpeg, .png"
                  />
                </span>
              </div>
              {imageUploadError ? <span className="mt-2 text-red-600">{imageUploadError}</span> : <p className="mt-2 text-gray-500">Upload your company logo. Use a square image with a transparent background. Maximum size is 1 MB.</p>}

            </div>

          </div>
        </div>
        <div className="sm:flex sm:justify-end mt-4 border-t border-gray-200 pt-5">
          <div className="w-full sm:w-auto inline-flex rounded-md shadow-sm">
            <button type="submit" className="w-full inline-flex justify-center py-2 px-4 border border-transparent  leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out">
              {buttonClicked ? <span>Loading...</span> : <span>Submit - {cms.price}</span>}
            </button>
          </div>
        </div>
        {error && <div className="py-2 text-center text-red-600">{error}</div>}
      </div>
    </div>
  </form >
}

export default JobPostForm;