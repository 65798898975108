import React from "react";

import JobPostForm from "../components/jobpostform";
import Layout from "../components/layout";
import Pricing from "../components/pricing";
import SEO from "../components/seo";
import cms from "../components/cms/cms";

function PostJob() {
  return (
    <Layout>
      <SEO
        keywords={[`${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `remote ${cms.keyword} jobs`]}
        title="Post a Job"
      />
      <Pricing />
      <JobPostForm />
    </Layout>
  );
}

export default PostJob;
