import React from "react";
import cms from "./cms/cms";

const Pricing = () => (cms.keyword === "Scala" || cms.keyword === "Rust") ? <div className="bg-gray-100">
  <div className="mt-8">
    <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
            <div>
              <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600" id="tier-standard">
                Standard
              </h3>
            </div>
            <div className="mt-4 flex items-baseline text-4xl leading-none font-extrabold">
              {cms.price}
            </div>
            <p className="mt-5 text-lg leading-7 text-gray-500">
              Standard one-off job post.
            </p>
          </div>
          <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-white border-t border-gray-200 space-y-6 sm:p-10 sm:pt-6">
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Reach thousands of {cms.keyword} developers
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Featured job post on the homepage for 30 days
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Shared across our social media channels reaching thousands of followers
                </p>
              </li>

            </ul>
            <div className="rounded-md shadow">
              <a href="#form" className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-standard">
                Get started now
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
            <div>
              <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600" id="tier-standard">
                Featured Company
              </h3>
            </div>
            <div className="mt-4 flex items-baseline text-4xl leading-none font-extrabold">
              Custom pricing
            </div>
            <p className="mt-5 text-lg leading-7 text-gray-500">
              Feature your company. Reach more {cms.keyword} devs.
            </p>
          </div>
          <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-white border-t border-gray-200  space-y-6 sm:p-10 sm:pt-6">
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Reach tens of thousands of {cms.keyword} developers
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Permanently featured job posts on the homepage
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Shared across our social media channels reaching thousands of followers
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Featured company listing on the homepage to make your company stand out
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Company page to describe your company in more detail
                </p>
              </li>

              <li className="flex items-start">
                <div className="flex-shrink-0">

                  <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Distinctive premium appearance to stand out from standard job posts
                </p>
              </li>
            </ul>
            <div className="rounded-md shadow">
            <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://forms.gle/hx74EmZZhdjf6noQ7"
                    eventCategory="Featured Company" eventAction="Click" eventLabel="Contact Us"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" aria-describedby="tier-standard">
                Contact us for more details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> : <></>


export default Pricing;